import React from 'react';
import {Container, Row, Col} from 'react-bootstrap';
import {injectIntl} from "gatsby-plugin-react-intl";
import TestimonialsData from "../../content/testimonials.json"
import quotes from "../../images/quotes.svg";
import ImageFluid from "../misc/image-fluid";

class Testimonials extends React.Component {

    constructor(props, context) {
        super(props, context);

        this.state = {
            content: TestimonialsData[props.intl.locale].testimonials[0]
        }
    }

    componentDidMount() {
        let min= 0, max = TestimonialsData[this.props.intl.locale].testimonials.length - 1, random = Math.floor(Math.random() * (+max - +min)) + +min;
        this.setState({
            content: TestimonialsData[this.props.intl.locale].testimonials[random]
        })
    }

    render() {
        return (
            <div className={`home-testimonials`}>
                <Container>
                    <div className={`title`}>{TestimonialsData[this.props.intl.locale].title}</div>
                    <Row>
                        <Col lg={5}>
                            <div className={`image`}>
                                <div className={`quotes`}><img src={quotes} alt={``} /></div>
                                <ImageFluid alt={``} filename={this.state.content.image} />
                            </div>
                        </Col>
                        <Col lg={7}>
                            <div className={`testimonial`}>
                                <div className={`logo`}><ImageFluid alt={``} filename={this.state.content.logo} /></div>
                                <div className={`divider`}></div>
                                <div className={`summary`}>{this.state.content.summary}</div>
                                <div className={`text`} dangerouslySetInnerHTML={{ __html: this.state.content.text }}></div>
                                <div className={`author`}>{this.state.content.author}</div>
                                <div className={`role`}>{this.state.content.role}</div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }
}

export default injectIntl(Testimonials)
