import React from "react";
import { injectIntl, FormattedMessage } from "gatsby-plugin-react-intl"
import { Button, FormControl, Form } from "react-bootstrap"
import { createEmailContact } from "../../request";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

class CenteredMailingList extends React.Component {

    constructor(props, context) {
        super(props, context);

        this.state = {
            email: "",
            honeyPot: null,
            tags: ["tag_newsletter"],
            isProcessing: false,
            emailError: false,
            serverError: false,
            success: false
        }
    }

    handleInputChange = event => {
        const target = event.target;
        const value = target.value;

        this.setState({
            email: value
        });

    }

    handleHoneyPotChange = event => {
        const target = event.target;
        const value = target.value;

        this.setState({
            honeyPot: value
        });
    }

    handleSubmit = event => {
        event.preventDefault();
        event.stopPropagation();

        this.setState({
            isProcessing: true,
            emailError: false,
            serverError: false
        });

        if (!this.state.email) {

            this.setState({
                isProcessing: false,
                emailError: true
            });

            this.clearErrors();

        } else if (!this.validateEmail(this.state.email)) {

            this.setState({
                isProcessing: false,
                emailError: true
            });

            this.clearErrors();

        } else {

            if (!this.state.honeyPot) {

                let language = "en", region = "US";
                if (navigator && navigator.language) {
                    let navigatorLanguage = navigator.language.split("-");
                    if (navigatorLanguage && navigatorLanguage.length === 2){
                        language = navigatorLanguage[0].toLowerCase();
                        region = navigatorLanguage[1].toUpperCase();
                    }
                }

                createEmailContact({email: this.state.email, tags: this.state.tags, language: language, region: region}).then(() => {

                    this.setState({
                        isProcessing: false,
                        email: "",
                        success: true,
                        serverError: false
                    });

                }).catch((e) => {

                    this.setState({
                        isProcessing: false,
                        success: false,
                        serverError: true
                    });

                    this.clearErrors();
                });

            } else {

                this.setState({
                    isProcessing: false,
                    success: false,
                    serverError: true
                });

                this.clearErrors();

            }

        }
    }

    clearErrors = () => {
        setTimeout(() => {
            this.setState({
                emailError: false,
                serverError: false,
                success: false
            });
        }, 8000);
    }

    validateEmail = email => {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    render() {
        return (
            <div className={`centered-mailing-list`}>
                <div className={`title`}><FormattedMessage id="components.centeredMailingList.title" /></div>
                <div className={`text`} dangerouslySetInnerHTML={{ __html: this.props.intl.formatMessage({ id: "components.centeredMailingList.text" }) }}></div>
                <div className={`form`}>
                    <Form className={`form-area`} onSubmit={this.handleSubmit}>
                        <div className="form-field-group">
                            <Form.Label className="form-label"><FormattedMessage id="components.centeredMailingList.email.label" /></Form.Label>
                            <FormControl type="text" value={this.state.email} name={`email`} className="form-field" onChange={this.handleInputChange} />

                            <div style={{position: "absolute", left: "-9999px", overflow: "hidden"}} aria-hidden="true">
                                <input className="form-field" placeholder={this.props.intl.formatMessage({ id: "components.centeredMailingList.email.placeholder" })} type="text" onChange={this.handleHoneyPotChange} />
                            </div>

                            {this.state.emailError === true &&
                            <>
                                <div className={`feedback-text error`}><FormattedMessage id="components.centeredMailingList.email.error" /></div>
                                <div className={`feedback-icon error`}><FontAwesomeIcon size={`lg`} icon="exclamation-circle" /></div>
                            </>
                            }

                            {this.state.serverError === true &&
                            <>
                                <div className={`feedback-text error`}><FormattedMessage id="components.centeredMailingList.email.serverError" /></div>
                                <div className={`feedback-icon error`}><FontAwesomeIcon size={`lg`} icon="exclamation-circle" /></div>
                            </>
                            }

                            {this.state.success === true &&
                            <>
                                <div className={`feedback-text success`}><FormattedMessage id="components.centeredMailingList.email.success" /></div>
                                <div className={`feedback-icon success`}><FontAwesomeIcon size={`lg`} icon="check-circle" /></div>
                            </>
                            }

                        </div>
                        <Button className={`form-button`} variant="secondary" type="submit" block disabled={this.state.isProcessing}><FormattedMessage id="components.mailingList.email.button" /></Button>
                    </Form>

                </div>

            </div>
        )
    }
}

export default injectIntl(CenteredMailingList)
