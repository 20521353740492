import React from "react"
import Layout from "../../../components/layout"
import Seo from "../../../components/seo"
import { injectIntl } from "gatsby-plugin-react-intl"
import ClientsTicker from "../../../components/misc/clients-ticker"
import DemoTrial from "../../../components/ctas/demo-trial"
import HeroPagesCenter from "../../../components/heroes/hero-pages-center"
import { Col, Container, Row } from "react-bootstrap"
import Testimonials from "../../../components/home/testimonials"
import CenteredMailingList from "../../../components/ctas/centered-mailing-list"

const FormsThankYouPage = ({ intl, location }) => (
  <Layout>
    <Seo lang={intl.locale} title={intl.formatMessage({id : "pages.forms.thankYou.title"})} description={intl.formatMessage({id : "pages.forms.thankYou.description"})} pathname={location.pathname} />
    <HeroPagesCenter theme={`primary`} content={{title: intl.formatMessage({id : "pages.forms.thankYou.title"}), text: intl.formatMessage({id : "pages.forms.thankYou.description"})}} />
    <div className={`trial`}>
      <div className={`top-overflow-wrapper`}>
        <Container>
          <Row>
            <Col md={2}></Col>
            <Col md={8}>
              <div className={`trial-form-box`}>
                <CenteredMailingList />
              </div>
            </Col>
            <Col md={2}></Col>
          </Row>
          <Testimonials />
          <ClientsTicker content={{title: intl.formatMessage({ id: "pages.forms.thankYou.ticker.title" })}}/>
        </Container>
      </div>
    </div>
    <DemoTrial />
  </Layout>
)

export default injectIntl(FormsThankYouPage)
